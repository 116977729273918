import React, { useState, useEffect } from 'react'
import { Table, Spin } from "antd";

const Main = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const relevantColumns = [
    "id", "table_name", "user_question", "generated_sql", "comment", "uploaded_by", "status"
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetch('https://api.defog.ai/get_experiments');
      const json = await response.json();
      setData(json.data.map((el, index) => ({...el, key: index, 0: <a href={`/${el[0]}`}>{el[0]}</a>})));
      let cols = json.columns;
      cols = cols.map((col, index) => ({
        title: col,
        dataIndex: index,
        key: index,
      })).filter((col) => relevantColumns.includes(col.title));
      setColumns(cols);
      setLoading(false);
    };
    fetchData();
  }, []);
  
  return (
    <div>
      <h1>Experiment Log</h1>
      {loading ? <Spin size='large' /> : <Table dataSource={data} columns={columns} />}
    </div>
  );
}

export default Main