import React from 'react'
import Main from './Main'
import Experiment from './Experiment'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Main />}/>
        <Route path="/:id" element={<Experiment />}/>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
