import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { Row, Col, Spin } from 'antd';

const Experiment = () => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetch('https://api.defog.ai/get_experiments');
      const json = await response.json();
      setData(json.data.filter((el) => String(el[0]) === id)[0]);
      console.log(data);

      let cols = json.columns;
      setColumns(cols);
      setLoading(false);
    };
    fetchData();
  }, []);
  
  return (
    <div>
      {loading ? <Spin size='large' /> : <>
      <h1>Experiment Log for {data["0"]}</h1>
      <h2><a href="/">Back to main</a></h2>
      <h3>To replicate this, visit <a href={`https://defog.ai/explore_prompts?table=${data["1"]}`}>{`https://defog.ai/explore_prompts?table=${data["1"]}`}</a> and change parameters to the ones below</h3>
      
      {columns.map((col, index) => (
        <Row style={{paddingBottom: "1em"}}>
          <Col span={4}>{col}</Col>
          <Col span={20}>{
            typeof(data[index]) === "object" ?
            JSON.stringify(data[index]) : data[index]
          }</Col>
        </Row>
      ))}
      </>}
    </div>
  );
}

export default Experiment